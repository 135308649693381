/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        // console.log('src/js/pages/main.js');

        /* Animation titres */
        var timeline = new TimelineMax({paused: true});
        var $splitItem = new SplitText(".anim-title", {type: 'words, chars'});
        timeline.staggerFrom($splitItem.chars, 0.8, {y: 10, autoAlpha: 0, transformOrigin: "0% 50% -50", ease: Back.easeOut}, '0.035');
        timeline.play();

        /* Slider home */
        var mySwiper = new Swiper('.slider .swiper-container', {
            speed: 1000,
            autoplay: true,
            spaceBetween: '2%',
            centeredSlides: true,
            loop: true,
            loopedSlides: 3,
            slidesPerView: 3,
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 10
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        var mySwiper = new Swiper('.galerie', {
            speed: 800,
            autoplay: true,
            spaceBetween: '2%',
            centeredSlides: true,
            loop: true,
            loopedSlides: 3,
            slidesPerView: 3,
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 10
                }
            }
        });

        /* Slider home */
        $('#partie-assurance-1').hover(
            function(){ $('.item').removeClass('active') }
        )

        $('.image_video').on('click', function(ev) {

            $('.image_video').hide();

            $(".video_yt iframe")[0].src += "?autoplay=1";
            ev.preventDefault();

        });

    }
}
